import Form from "../atoms/enroll-form"
import FormSection from "../atoms/enroll-form-section"
import FormSectionHeading from "../atoms/enroll-form-section-heading"
import FormLabel from "../atoms/enroll-form-label"
import FormLabelText from "../atoms/enroll-form-label-text"
import FormInput from "../atoms/enroll-form-input"
import FormRadioField from "../atoms/enroll-form-radio-field"
import FormTextArea from "../atoms/enroll-form-textarea"
import FormCheckbox from "../atoms/enroll-form-checkbox"
import FormExpend from "../atoms/enroll-form-expend"
// import Recaptcha from "react-recaptcha"
import FormSectionBtn from "../atoms/enroll-form-section-btn"
import FormBtn from "../atoms/enroll-form-btn"
import errorLabel from "../molecules/enroll-form-error"
import React from "react"
import LinkStyled from "../atoms/action-paragraph-link"

const form = props => (
  <Form onSubmit={props.handleSubmit} novalidate>
    <FormSection>
      <FormSectionHeading>1. Dane osobowe</FormSectionHeading>
      <FormLabel>
        <FormLabelText>
          Imię dziecka <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"Jan"}
          type={"text"}
          name={"childName"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.childName}
          error={props.errors.childName && props.touched.childName}
          success={!props.errors.childName && props.values.childName}
        />
        {props.errors.childName &&
          props.touched.childName &&
          errorLabel(props.errors.childName)}
      </FormLabel>
      <FormLabel>
        <FormLabelText>
          Nazwisko dziecka <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"Kowalski"}
          type={"text"}
          name={"childSurname"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.childSurname}
          error={props.errors.childSurname && props.touched.childSurname}
          success={!props.errors.childSurname && props.values.childSurname}
        />
        {props.errors.childSurname &&
          props.touched.childSurname &&
          errorLabel(props.errors.childSurname)}
      </FormLabel>
      <FormLabel>
        <FormLabelText>
          Wiek dziecka <span>*</span>
        </FormLabelText>
        <FormInput
          type={"number"}
          placeholder={"7"}
          min={3}
          max={99}
          name={"childAge"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.childAge}
          error={props.errors.childAge && props.touched.childAge}
          success={!props.errors.childAge && props.values.childAge}
        />
        {props.errors.childAge &&
          props.touched.childAge &&
          errorLabel(props.errors.childAge)}
      </FormLabel>
      <FormLabel>
        <FormLabelText>
          Imię rodzica <span>*</span>
        </FormLabelText>
        <FormInput
          type={"text"}
          placeholder={"Paweł"}
          name={"childParent"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.childParent}
          error={props.errors.childParent && props.touched.childParent}
          success={!props.errors.childParent && props.values.childParent}
        />
        {props.errors.childParent &&
          props.touched.childParent &&
          errorLabel(props.errors.childParent)}
      </FormLabel>
    </FormSection>
    <FormSection>
      <FormSectionHeading>2. Informacje dodatkowe</FormSectionHeading>
      <FormLabel>
        <FormLabelText>
          Język kursu: <span>*</span>
        </FormLabelText>
        <FormRadioField>
          <FormInput
            type={"radio"}
            name={"lang"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"ang"}
          />
          <span />
          Angielski
        </FormRadioField>
        <FormRadioField>
          <FormInput
            type={"radio"}
            name={"lang"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"matma"}
          />
          <span />
          Matematyka
        </FormRadioField>
        <FormRadioField>
          <FormInput
            type={"radio"}
            name={"lang"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"chemia"}
          />
          <span />
          Chemia
        </FormRadioField>
        <FormRadioField>
          <FormInput
            type={"radio"}
            name={"lang"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"czytanie"}
          />
          <span />
          Czytanie
        </FormRadioField>
        <FormRadioField>
          <FormInput
            type={"radio"}
            name={"lang"}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={"robotyka"}
          />
          <span />
          Robotyka
        </FormRadioField>
        {props.errors.lang &&
          props.touched.lang &&
          errorLabel(props.errors.lang)}
      </FormLabel>

      <FormLabel>
        <FormLabelText>Uwagi:</FormLabelText>
        <FormTextArea
          placeholder={
            "Proszę wpisać nazwę interesującego nasz kursu lub pozostaw dodatkowe uwagi."
          }
          name={"additional"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.additional}
          rows={5}
        />
        {props.errors.additional &&
          props.touched.additional &&
          errorLabel(props.errors.additional)}
      </FormLabel>
    </FormSection>
    <FormSection>
      <FormSectionHeading>3. Dane kontaktowe</FormSectionHeading>
      <FormLabel>
        <FormLabelText>
          E-mail <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"jan.kowalski@emial.pl"}
          type={"text"}
          name={"email"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.email}
          error={props.errors.email && props.touched.email}
          success={!props.errors.email && props.values.email}
        />
        {props.errors.email &&
          props.touched.email &&
          errorLabel(props.errors.email)}
      </FormLabel>
      <FormLabel>
        <FormLabelText>
          Numer telefonu <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"123-456-789"}
          type={"text"}
          name={"phone"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.phone}
          error={props.errors.phone && props.touched.phone}
          success={!props.errors.phone && props.values.phone}
        />
        {props.errors.phone &&
          props.touched.phone &&
          errorLabel(props.errors.phone)}
      </FormLabel>
    </FormSection>
    <FormSection long>
      <FormSectionHeading>4. Wymagane zgody</FormSectionHeading>
      <FormLabel $featured="true">
        <FormLabelText></FormLabelText>
        <FormLabelText>
          Zgoda na przetwarzanie danych osobowych <span>*</span>
        </FormLabelText>
        <FormInput
          type={"checkbox"}
          name={"zgoda1"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          checked={props.values.zgoda1}
        />
        <FormCheckbox>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"20px"}
              height={"20px"}
              viewBox="0 0 24 24"
            >
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </span>
        </FormCheckbox>
        <FormExpend>
          Wysyłając zgłoszenie za pośrednictwem powyższego formularza, zgadzasz
          się na przetwarzanie swoich danych osobowych w celu przeprowadzenia
          rekrutacji do szkoły językowej na podstawie art. 6 ust. 1 lit. a
          Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE oraz art. 23 ust. 1
          pkt 1 ustawy z dnia 29 sierpnia 1997 roku o ochronie danych osobowych
          (t.j. Dz.U. 2016 poz. 922 ze zm.). Administratorem danych osobowych
          jest Aleksandra Sydor prowadząca działalność gospodarczą pod nazwą
          Amuzo Aleksandra Sydor siedzibą w Pyskowicach przy ul. Wojska
          Polskiego 25, nr NIP 9691541386. Więcej o nasze polityce prywatności{" "}
          <LinkStyled to={"/polityka-prywatnosci"}>na tej stronie</LinkStyled>.
        </FormExpend>
        {!props.values.zgoda1 &&
          props.errors.zgoda1 &&
          errorLabel(props.errors.zgoda1)}
      </FormLabel>
      <FormLabel $featured="true">
        <FormLabelText>
          Zgoda na otrzymywanie informacji handlowych
        </FormLabelText>
        <FormInput
          type={"checkbox"}
          name={"zgoda2"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          checked={props.values.zgoda2}
        />
        <FormCheckbox>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"20px"}
              height={"20px"}
              viewBox="0 0 24 24"
            >
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </span>
        </FormCheckbox>
        <FormExpend>
          Wyrażam zgodę na otrzymywanie informacji handlowych drogą
          elektroniczną na podstawie ustawy z dnia 18 lipca 2002 r. o
          świadczeniu usług drogą elektroniczną, w szczególności ofert,
          informacji o promocjach, rabatach, bieżących wydarzeniach i akcjach
          marketingowych, od Aleksandry Sydor prowadząca działalność gospodarczą
          pod nazwą Amuzo Aleksandra Sydor siedzibą w Pyskowicach przy ul.
          Wojska Polskiego 25, nr NIP 9691541386.
        </FormExpend>
        {props.errors.zgoda2 &&
          props.touched.zgoda2 &&
          errorLabel(props.errors.zgoda2)}
      </FormLabel>
      {/*<FormLabel recaptcha>*/}
      {/*  <Recaptcha*/}
      {/*    sitekey={"6LfIjaYUAAAAAE2HkOdskilgAP6iWf5UhTHqXa69"}*/}
      {/*    render={"explicit"}*/}
      {/*    onloadCallback={() => 1}*/}
      {/*    verifyCallback={(response) => {*/}
      {/*      props.setFieldValue("recaptcha", response)*/}
      {/*    }}*/}
      {/*    expiredCallback={() => {*/}
      {/*      props.setFieldValue("recaptcha", "")*/}
      {/*      props.setFieldError("recaptcha", "Weryfikacja wygasła.")*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  {*/}
      {/*    props.errors.recaptcha &&*/}
      {/*    props.touched.recaptcha*/}
      {/*    && errorLabel(props.errors.recaptcha)*/}
      {/*  }*/}
      {/*</FormLabel>*/}
    </FormSection>
    <FormSectionBtn>
      <FormBtn type={"reset"} onClick={props.handleReset}>
        Wyczyść
      </FormBtn>
      <FormBtn type={"submit"} disabled={!props.isValid} $featured="true">
        Wyślij podanie
      </FormBtn>
    </FormSectionBtn>
  </Form>
)

export default form
