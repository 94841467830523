import styled from "styled-components"

export default styled.label`
  cursor: pointer;
  
  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 3px solid #bebabe;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    transition: all 100ms ease-in-out;
  }
  
  input[type=radio]:checked + span {
    background: ${({ theme }) => theme.pink};
    border: 3px solid ${({ theme }) => theme.pink};
  }
`;