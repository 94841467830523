import React, { useEffect, useState } from "react"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heading from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"
import styled from "styled-components"
import { Formik } from "formik"
import { useLocation } from "@reach/router"
import * as Yup from "yup"
import axios from "axios"
import ActionLink from "../atoms/action-paragraph-link"
import ActionClick from "../atoms/action-paragraph-click"
import { endpoint } from "../../api"
import enrollForm from "../molecules/enroll-form"
import EnrollStatus from "../molecules/enroll-status"

const Container = styled.article`
  margin-top: 1vh;
`

// const phoneRegExp = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/
const FormSchema = Yup.object().shape({
  childName: Yup.string()
    .max(50, "Imię za długie.")
    .required("Pole wymagane."),
  childParent: Yup.string()
    .max(50, "Imię za długie.")
    .required("Pole wymagane."),
  childSurname: Yup.string()
    .max(50, "Nazwisko za długie.")
    .required("Pole wymagane."),
  childAge: Yup.number()
    .min(3, "Minimalny wiek to 3 lat.")
    .max(99, "Maksymalny wiek to 99 lat")
    .required("Pole wymagane."),
  email: Yup.string()
    .email("Nieprawidłowy adres e-mail.")
    .required("Pole wymagane."),
  phone: Yup.string().required("Pole wymagane."),
  lang: Yup.string().required("Pole wymagane."),
  additional: Yup.string(),
  zgoda1: Yup.boolean()
    .oneOf(
      [true],
      "Musisz wyrazić zgodę na przetwarzanie danych osobowych, abyśmy mogli przetworzyć twoje podanie."
    )
    .required(
      "Musisz wyrazić zgodę na przetwarzanie danych osobowych, abyśmy mogli przetworzyć twoje podanie."
    ),
  zgoda2: Yup.boolean(),
})

const EnrollPage = () => {
  const location = useLocation()
  const [state, setState] = useState({
    status: "form",
    open: true,
    additional: "",
  })

  useEffect(() => {
    if (location.state) {
      setState(prev => ({
        ...prev,
        additional: location.state.additional ?? "",
      }))
    }
  }, [location])

  const formFail = () => {
    setState(prev => ({ ...prev, status: "fail" }))
  }

  const formSuccess = () => {
    setState(prev => ({ ...prev, status: "success" }))
  }

  const onSubmit = (values, actions) => {
    axios
      .post(endpoint.enrollSubmit, { ...values, type: "enroll" })
      .then(response => {
        if (response.data === "ok") {
          actions.resetForm()
          formSuccess()
        } else {
          formFail()
        }
      })
      .catch(() => {
        formFail()
      })
  }

  const formEmbed = () => (
    <Formik
      initialValues={{
        childName: "",
        childSurname: "",
        childAge: "",
        childParent: "",
        email: "",
        phone: "",
        lang: "",
        additional: state.additional,
        zgoda1: false,
        zgoda2: false,
      }}
      onSubmit={onSubmit}
      validationSchema={FormSchema}
      enableReinitialize
    >
      {enrollForm}
    </Formik>
  )

  const handleStatus = () => {
    if (state.status === "form") {
      return formEmbed()
    }

    if (state.status === "fail") {
      return (
        <>
          {formEmbed()}
          <EnrollStatus title={"Coś poszło nie tak..."}>
            Nie udało się zarejestrować zgłoszenia w naszym systemie
            rekrutacyjnym. Spróbuj ponownie raz jeszcze za kilka chwil.
            <br />
            <br />
            Jeśli ten błąd się powtarza, prosimy o{" "}
            <ActionLink to={"/kontakt"} title={"Strona kontaktowa"}>
              kontakt
            </ActionLink>
            .
          </EnrollStatus>
        </>
      )
    }

    if (state.status === "success") {
      return (
        <>
          <EnrollStatus title={"Gratulacje!"}>
            Zgłoszenie zostało zarejestrowane w naszym systemie rekrutacyjnym.
            Będziemy się z tobą kontaktować w sprawie dalszych ustaleń. Dobry
            wybór!
            <br />
            <br />
            Kliknij{" "}
            <ActionClick
              onClick={() => setState(prev => ({ ...prev, status: "form" }))}
              title={"Wypełnij raz jeszcze"}
            >
              tutaj
            </ActionClick>
            , aby wypełnić raz jeszcze formularz.
          </EnrollStatus>
        </>
      )
    }

    return formEmbed()
  }

  return (
    <Wrapper>
      <ParagraphItalic>zapisz-się</ParagraphItalic>
      <Heading>Formularz zgłoszeniowy do Centrum Edukacji AMUZO</Heading>

      <Container>
        {state.open ? (
          handleStatus()
        ) : (
          <>
            <EnrollStatus title={"Rekrutacja zamknięta"}>
              Obecnie rekrutacja jest zamknięta. Prosimy obserować naszą stronę
              internetową lub social media.
              <br />
              <br />
              Zapraszamy również do{" "}
              <ActionLink to={"/kontakt"} title={"Strona kontaktowa"}>
                kontaktu
              </ActionLink>{" "}
              z nami.
            </EnrollStatus>
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default EnrollPage
