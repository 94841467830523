import styled from "styled-components"

export default styled.button`
  color: ${({theme}) => theme.pink};
  font-weight: 700;
  text-decoration: none;
  position: relative;
  display: inline-table;
  overflow: hidden;
  background: transparent;
  border: none;
  padding: 0;
  
  :after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background: ${({theme}) => theme.pink};
    transform: scale(0);
    transition: all 300ms cubic-bezier(.4,0,0,1);
  }
  
  :hover:after {
    transform: scale(1);
  }
`;