import React from "react"
import Layout from "../../components/organisms/layout"
import SEO from "../../components/seo"
import PageBackground from "../../components/atoms/page-background"
import EnrollPage from "../../components/organisms/enroll-page"
import { graphql } from "gatsby"

const ContactPageWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="Formularz zgłoszeniowy na kurs" />
    <PageBackground img={data.placeholderImage.childImageSharp.fluid} />
    <EnrollPage />
  </Layout>
)

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "ContactImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4608) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default ContactPageWrapper
